import styled from "styled-components";

export const ErrorContainer = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  gap: 32px;
  padding: 32px 0px;
`;
export const CharSliderContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-end;
`;
export const ProductsTotal = styled.div`
  display: flex;
  gap: 8px;
  color: #646464;
  font-weight: 200;
  font-size: 14px;
  align-items: center;
`;
export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
export const ButtonLink = styled.a`
  display: flex;
  text-decoration: none;
`;
