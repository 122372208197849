import React from 'react';
import './ContentTabs.css'

const ContentTabs = ({ tabs, activeTab, onTabClick }) => {
  return (
    <div className="container__tabs">
      {tabs.map(tab => (
        <a
          href={tab.route}
          key={tab.id}
          className={activeTab === tab.id ? 'tab__active' : 'tab__inactive'}
          onClick={(e) => {
            e.preventDefault(); 
            onTabClick(tab.route)
          }}
          style={{ textDecoration: "none", color: "inherit" }} 
        >
          {tab.name}
        </a>
      ))}
    </div>
  );
};

export default ContentTabs;