import {
  SEVERITY_CRITICAL_ID,
  SEVERITY_MAJOR_ID,
  SEVERITY_HIGH_ID,
  SEVERITY_MEDIUM_ID,
  SEVERITY_LOW_ID,
  SEVERITY_MINOR_ID,
  SEVERITY_CRITICAL,
  SEVERITY_MAJOR,
  SEVERITY_HIGH,
  SEVERITY_MEDIUM,
  SEVERITY_LOW,
  SEVERITY_MINOR,
  ENTITY_USER,
  ENTITY_ESCALATION_POLICY,
  ENTITY_SCHEDULE,
  ENTITY_SERVICE,
  ENTITY_TEAMS,
} from '@/utils/constants';

const WHITE_COLOR = '#fff';
export const SEVERITY_CRITICAL_COLOR = '#8C1823';
export const SEVERITY_MAJOR_COLOR = '#E4626F';
export const SEVERITY_HIGH_COLOR = '#FEB2B2';
export const SEVERITY_MEDIUM_COLOR = '#FFE2B1';
export const SEVERITY_LOW_COLOR = '#9AE6B4';
export const SEVERITY_MINOR_COLOR = '#117170';

interface SeverityOutput {
  bgColor: string;
  name: string;
  textColor?: string;
}

export const severityMapping: {
  [key: number]: SeverityOutput;
} = {
  [SEVERITY_CRITICAL_ID]: {
    bgColor: SEVERITY_CRITICAL_COLOR,
    name: SEVERITY_CRITICAL,
    textColor: WHITE_COLOR,
  },
  [SEVERITY_MAJOR_ID]: {
    bgColor: SEVERITY_MAJOR_COLOR,
    name: SEVERITY_MAJOR,
    textColor: WHITE_COLOR,
  },
  [SEVERITY_HIGH_ID]: {
    bgColor: SEVERITY_HIGH_COLOR,
    name: SEVERITY_HIGH,
  },
  [SEVERITY_MEDIUM_ID]: {
    bgColor: SEVERITY_MEDIUM_COLOR,
    name: SEVERITY_MEDIUM,
  },
  [SEVERITY_LOW_ID]: {
    bgColor: SEVERITY_LOW_COLOR,
    name: SEVERITY_LOW,
  },
  [SEVERITY_MINOR_ID]: {
    bgColor: SEVERITY_MINOR_COLOR,
    name: SEVERITY_MINOR,
    textColor: WHITE_COLOR,
  },
};

export const severityLegend = Object.keys(severityMapping).map((key) => {
  const severityId = Number(key);
  const { bgColor } = severityMapping[severityId];
  return {
    bgColor,
    severity: severityMapping[severityId].name,
  };
});

export const getSeverity = (severity: number): SeverityOutput => {
  return severityMapping[severity];
};

export const severityInitialOptions = [
  {
    entity_id: SEVERITY_CRITICAL_ID,
    entity_name: SEVERITY_CRITICAL,
  },
  {
    entity_id: SEVERITY_MAJOR_ID,
    entity_name: SEVERITY_MAJOR,
  },
  {
    entity_id: SEVERITY_HIGH_ID,
    entity_name: SEVERITY_HIGH,
  },
  {
    entity_id: SEVERITY_MEDIUM_ID,
    entity_name: SEVERITY_MEDIUM,
  },
  {
    entity_id: SEVERITY_LOW_ID,
    entity_name: SEVERITY_LOW,
  },
  {
    entity_id: SEVERITY_MINOR_ID,
    entity_name: SEVERITY_MINOR,
  },
];

export const entitiesInitialOptions = [
  {
    entity_id: ENTITY_ESCALATION_POLICY,
    entity_name: 'Escalation Policy',
  },
  {
    entity_id: ENTITY_SCHEDULE,
    entity_name: 'Schedules',
  },
  {
    entity_id: ENTITY_SERVICE,
    entity_name: 'Services',
  },
  {
    entity_id: ENTITY_TEAMS,
    entity_name: 'Teams',
  },
  {
    entity_id: ENTITY_USER,
    entity_name: 'Users',
  },
];
