import { useState, useEffect } from "react";
import {
  faX,
  faFileCsv,
  faCartShopping,
  faTrash
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Pagination from "../../../components/PaginationA";
import logo from "../../../assets/PagerDuty-WhiteRGB_white.svg";
import { apiRoutes, apiRequest } from "../../../services";
import useCSV from "../../../components/DownloadCSV/index.js"
import { useAuth } from "../../../UserContext.js";
import Spinner from "../../../components/Spinner/Spinner";
import { useNavigate } from "react-router-dom";


export default function ModalAllAlertsTeam({selectedAlert, setShowModalMoreAlert }) {
  const navigate = useNavigate();
  const { addAlertToCart, cartAlert, deleteAllAlertsTeamInCart } = useAuth()
  const [firstElement, setFirstElement] = useState(0);
  const [lastElement, setLastElement] = useState(7);
  const [allAlertsTeam, setAllAlertsTeam] = useState([]);
  const [idAllAlertsTeam, setIdAllAlertsTeam] = useState([]);
  const [loadingAlerts, setLoadingAlerts] = useState(false);
  const [loadingCsv, setLoadingCsv] = useState(false);
  const { downloadCSV } = useCSV();
  

  useEffect(() => {
    getAllAlertsByTeam() 
  }, []);

  const getAllAlertsByTeam = async () => {
    setLoadingAlerts(true)
    const ids = [...selectedAlert.relations_data];
    setIdAllAlertsTeam(prevState => (
      [
        ...(selectedAlert.relations_data || []), 
        selectedAlert.entity_id
      ]
    ));
    const [error, data] = await apiRequest({
      method: "get",
      url: `${apiRoutes.getAllTeam}?ids=${ids}`,
    });

    if(data?.length > 0) {
      setLoadingAlerts(false)
      setAllAlertsTeam(prevState => (
        [ ...mapSelectedAlerts(selectedAlert.alerts || []), ...mapAlertsData(data)]
      ));
    } 
  };

  const mapAlertsData = (alerts) => {
    return alerts.map(item => ({
      id: item.id,
      numeric_impact: item.numeric_impact,
      pd_entities_labels: item.pd_entities_labels,
      severity: item.severity,
      title: item.title,
      multiple: true,
      team_name: selectedAlert.entity
    }));
  };

  const mapSelectedAlerts = (alerts) => {
    return alerts.map(alert => ({
      ...alert,
      multiple: true, 
      team_name: selectedAlert.entity
    }));
  };
  

  const backgroundStatus = (status) => {
    const severityMapping = {
      6: { 'backgroundStatus': '#FFE7E7', 'color': '#f00' },
      5: { 'backgroundStatus': '#ff872e', 'color': '#fff' },
      4: { 'backgroundStatus': '#f7c604', 'color': '#fff' },
      3: { 'backgroundStatus': '#690375', 'color': '#fff' },
      2: { 'backgroundStatus': '#329cad', 'color': '#fff' },
      12: { 'backgroundStatus': '#cecece', 'color': '#808080' },
    };
    return severityMapping[status] ||  { 'backgroundStatus': '#329cad', 'color': '#fff' };
  }

  const showTextSeverity = (severity) => {
    const severityMapping = {
      6: 'Critical',
      5: 'Major',
      4: 'High',
      3: 'Medium',
      2: 'Low',
      12: 'Minor',
      1: 'Low'
    };

    return severityMapping[severity] || '';
  }

  
  const exportToCsv = async() => {
    console.log(idAllAlertsTeam);
    setLoadingCsv(true)
    const [error, data] = await downloadCSV(`${apiRoutes.downloadAlerts}?entity_id=${idAllAlertsTeam}`, "Alerts", "csv");
    if(!error) {
      setLoadingCsv(false)
    }
  }

  const renderExpectedValue = (parts) => {
    const expectedParts = parts[1]?.split(' The expected value is : ');
    return expectedParts.length > 1 ? expectedParts[1] : 'N/A';
  };

  const renderSections = (parsedValue) => (
    parsedValue?.metadata?.sections?.map((section, index) => (
      <div key={index} style={{ marginTop: '3px' }}>
        <span style={{ fontWeight: section.bold ? 'bold' : 'normal' }}>
          {section.label}:
        </span> {section.value}
      </div>
    ))
  );


  const allIdsInCart = allAlertsTeam.filter(alert => 
    cartAlert.some(cartItem => cartItem.id === alert.id)
  );

  return (
    <div className="container__detail">
        <section className="container__section container__sectionDashboard" >
          <div className="modal__containerDetail">
            <div className="header__modalHealthCheckAlert">
              <div>Health Check Alert</div>
              <div onClick={() => setShowModalMoreAlert(false)} className="icon__fax" ><FontAwesomeIcon icon={faX} /></div>
            </div>
            {/* <div >cart</div> */}
            <div className="container__allAlerts">
              <div className="container__cartAndTitle"> 
                <div className="title__cardTypeBetterWorse" style={{fontSize:"15px"}}>
                  Team Name: 
                  <span style={{marginLeft: "5px"}} className="title__nameTeam">{selectedAlert.entity}
                    <FontAwesomeIcon icon={faFileCsv} style={{ cursor: 'pointer', color: '#117170', marginLeft: '10', fontSize:'20px' }} onClick={() => exportToCsv()} /> 
                  </span>
                </div>
                {!loadingAlerts && 
                  <div>
                    {allIdsInCart.length > 0 ? 
                      <div onClick={() => deleteAllAlertsTeamInCart(allAlertsTeam)}>
                        <FontAwesomeIcon icon={faTrash} style={{ cursor: 'pointer', color: '#117170', marginLeft: '10', fontSize:'20px' }}  /> 
                      </div> :
                      <div onClick={() => addAlertToCart(allAlertsTeam)}>
                        <FontAwesomeIcon icon={faCartShopping} style={{ cursor: 'pointer', color: '#117170', marginLeft: '10', fontSize:'20px' }}  /> 
                      </div>
                    }
                  </div>}
              </div>
              <div className="container__scoreCircle" style={{paddingTop: "2px"}}>
                  <div className="punctuation__footer">
                    <div>Score:</div>
                    <div className="score__alerts">{selectedAlert.punctuation}</div>
                  </div>
              </div>
              <div className="container__paginationDashboard">
                <Pagination
                  numberOfElements={allAlertsTeam?.length}
                  showElementsPerPage={12}
                  onPaginationChange={(firstElement, lastElement) => {
                    setFirstElement(firstElement);
                    setLastElement(lastElement);
                  }}
                  />
              </div>
              {(loadingAlerts || loadingCsv) && <Spinner/>}
              {!loadingAlerts && !loadingCsv &&
                <div className="container__alertDetail">
                  {allAlertsTeam?.sort((a, b) => b.numeric_impact - a.numeric_impact)
                  ?.map((alert, alertIndex) => (alertIndex >= firstElement && alertIndex <= lastElement ? 
                    <div key={alertIndex}  className="team-column">
                      <div 
                      onClick={() => {
                        const params = new URLSearchParams({ searched: alert.title});
                        navigate(`/my-alerts-app?${params.toString()}`);
                      }}
                      className='title__alertModal' >{alert.title}</div>
                      <p className="description__newHome description__newAlert">
                        {(() => {
                          const parts = alert?.description?.split('The current value is : ');
                          if (parts && parts.length > 1) {
                            const currentValue = parts[1]?.split(' The expected value is : ')[0];
                            const parsedValue = currentValue?.startsWith('{') ? JSON.parse(currentValue) : null;

                            return (
                              <>
                                <b>The current value is : </b> {parsedValue?.current_value || currentValue || 'N/A'}
                                <p className="description__newHome">
                                  <b>The expected value is : </b> {renderExpectedValue(parts)}
                                </p>

                                {parsedValue?.metadata && (
                                  <div style={{ marginBottom: '15px', marginTop: '10px' }}>
                                    <b>{parsedValue.metadata.title}:</b>
                                    <div style={{ marginTop: '4px', marginBottom: '15px' }}>
                                      {renderSections(parsedValue)}
                                    </div>
                                  </div>
                                )}
                              </>
                            );
                          }
                          return 'N/A';
                        })()}
                      </p>
                      <div className="container__severityModal">
                          <div className="container__severityScore">
                            <div className="urgency__home"  style={{width: 'max-content', padding: '4px 8px 4px 8px', backgroundColor: backgroundStatus(alert.numeric_impact).backgroundStatus, color: backgroundStatus(alert.numeric_impact).color}} >{showTextSeverity(alert.numeric_impact)} </div>
                            <div className="punctuation__footer">
                              <div>Score:</div>
                              <div className="score__alerts">{alert.numeric_impact}</div>
                            </div>
                          </div>
                      </div>
                    </div> : null
                  ))}
                </div>}
            </div>
            <div className="footer__detail">
              <img className="logo__footer" src={logo}/>
            </div>
          </div>
        </section>
    </div>
  )
}