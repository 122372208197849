import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { forwardRef, useEffect, useImperativeHandle, useState, useRef } from "react";
import { useLocation } from 'react-router-dom';
import Jwt from "./../../utils/jwt";
import {
  NavbarContainer,
  SearchDiv,
  SearchDivIcon,
  SearchInput,
  RowSearchResults,
  SearchDivContainer,
  OptionSearch,
  SearchTitleRow,
  ProfileSection,
  DivFlex,
  PremiumReqModalSection
} from "./NavbarStyled.js";
import ModalHome from "../../components/ModalHome/ModalHome.js";
import {
  faSearch,
  faCartShopping,
  faTrash,
  faPlus
} from "@fortawesome/free-solid-svg-icons";
import CloudCheckMark from "../../assets/CloudCheckMark.svg";
import "./Navbar.css";
import { controlTour, openMenuSubject, reconnectModal, resetTourSteps, stepsState } from "../../services/subjects.js";
import { apiRequest, apiRoutes } from "../../services/index";
import { useNavigate } from "react-router-dom";
import { cartSolution} from "../../services/subjects.js";
import { showCartAlert} from "../../services/subjects.js";
import healthCheck from "../../assets/wallpaper1.png";
import pageyUser from "../../assets/pageyUser.png";
import Start from "../../assets/Start.svg";
import { useAuth } from "../../UserContext.js";
import { getTitle } from "../../utils/pageTitles.js";
import PremiumRequestModal from "../PremiumRequestModal/PremiumRequestModal.js";
import { haveClientDeliveryPermissions } from "../../utils/clientDelivery.js";

interface Props {
  menuItems: any;
}

const Navbar = forwardRef(({ menuItems }: Props, Ref) => {
  const location = useLocation();
  const [profile, setProfile] = useState([]);
  const [isLoadingProfile, setIsLoadingProfile] = useState(true);
  const [openFreeTrialModal, setOpenFreeTrialModal] = useState(false);
  const { cartAlert } = useAuth()
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [searchInput, setSearchInput] = useState<any>(null);
  const [userOptions, setUserOptions] = useState<any>(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [feedbackRating, setFeedbackRating] = useState<null | number>(null);
  const [feedbackDescription, setFeedbackDescription] = useState("");
  const [thankYouState, setThankYouState] = useState(false);
  const [detailCart, setDetailCart] = useState([]);
  const [showDetailCart, setShowDetailCart] = useState(false);
  const [requestSentSuccess, setRequestSentSuccess] = useState(false);
  const [loadindRequestSent, setLoadindRequestSent] = useState(false);
  const [showRequestModal, setShowRequestModal] = useState(false);
  const [selectedSubdomain, setSelectedSubdomain] = useState(false);
  const [showClientDeliveryDashboard, setShowClientDeliveryDashboard] =
  useState(false);
  const [, setHasClientDeliveryDashboardApp] = useState(false);
  const [filterSubdomain, setFilterSubdomain] = useState({});
  const [email, setEmail] = useState("");
  const [subdomain, setSubdomain] = useState([]);
  const [clientRole, setClientRole] = useState(null);
  const pathname = window.location.pathname;
  const [userRole, setUserRole] = useState("");
  useImperativeHandle(Ref, () => ({}));
  const detailCartRef = useRef(null);

  const [steps, setSteps] = useState([]);
  const tokenData = Jwt.getItem("all");
  const handleChangePremiumRequest = (e) => {
    setShowRequestModal(true);
  };
  const verifyClientDeliveryApp = (token) => {
    if (!token.claims || !token.claims.roles || !Array.isArray(token.claims.roles)) {
      return false;
    }

    for (const role of token.claims.roles) {
      if (role.apps && Array.isArray(role.apps)) {
        const hasApp = role.apps.some(app => app.app === "ClientDeliveryDashboardApp");
        if (hasApp) {
          setHasClientDeliveryDashboardApp(true);
          return true;
        }
      }
    }
    setHasClientDeliveryDashboardApp(false);
    return false;
  }
  const getClientDeliveryUserType = async () => {
    const token = Jwt.getItem("all");
    const role = getUserRole(token);
    setEmail(token?.sub);
    const hasApp = verifyClientDeliveryApp(token);
    if (role !== 'Customer' && hasApp) {
      const [error, data] = await apiRequest({
        method: "get",
        url: `${apiRoutes.clientDeliveryType}`,
      });
      if (data?.type_key) {
        data.type_key === "DELIVERY_MANAGER_TEAM_MANAGER" ? setClientRole("SUPER_USER") : setClientRole(data.type_key);
        setShowClientDeliveryDashboard(haveClientDeliveryPermissions(data.type_key));
      }
    }
  };
  function getUserRole(token) {
    if (token?.claims?.roles && Array.isArray(token.claims.roles) && token.claims.roles.length > 0) {
      return token.claims.roles[0].role;
    }
    return null;
  }
  const getSubdomain = async () => {
    const [error, data] = await apiRequest({
      method: "get",
      url: apiRoutes.getSubdomainHome,
    });

    if (data?.subdomains?.length > 0) {
      // sessionStorage.setItem("subdomain", JSON.stringify(data?.subdomains));
      const subdomain =  sessionStorage.subdomain && JSON.parse(sessionStorage.subdomain);
      !subdomain &&
        data?.subdomains &&
        data?.subdomains.length > 0 &&
        // toggleCheckboxSubdomain(data.subdomains[0], true);
      setSubdomain(data?.subdomains);
    }
  };
  useEffect(() => {
    getClientDeliveryUserType();
    // getProfile();
    // getMenu();
  }, []);
  
  useEffect(() => {
    const subdomain =
      sessionStorage.subdomain && JSON.parse(sessionStorage.subdomain);
    if (subdomain) {
      setSelectedSubdomain(true);
      setFilterSubdomain(subdomain);
    } else {
      getSubdomain();
    }
  }, []);
  const makeANewRequest = async (section) => {
    const [error, data] = await apiRequest({
      method: "post",
      url: apiRoutes.trialContact,
      data: {
        section: "Home",
        "request_details": "New Request"
      }
    });
    if (data?.status_code === 200) {
      setOpenFreeTrialModal(true);
    }
  }
  
useEffect(() => {
  // Suscribirse al BehaviorSubject para obtener el estado actual de los pasos
  const subscription = stepsState.subscribe((steps: any) => {
    setSteps(steps);
  });

  // Limpiar la suscripción cuando el componente se desmonte
  return () => subscription.unsubscribe();
}, []);
  const getMenu = (tokenData) => {
    let menu = tokenData.claims.roles.map((role) => {
      setUserRole(role.role);
        return role?.apps?.map((app) => {
            return {appName: app.app};
        });
    });
    setUserOptions(menu[0]);
};
  const getProfile = async () => {
    setIsLoadingProfile(true);
    const [error, data] = await apiRequest({
      method: "get",
      url: apiRoutes.userProfile,
      data: null,
      newDomain: false,
    });

    if (data?.data) {
      //data.data.customer_dynamic_view_mode = true
      setProfile(data?.data || []);
    } else {
      setIsLoadingProfile(false);
    }
  };
  useEffect(() => {
    if (tokenData !== null) {
        getMenu(tokenData);
    }
}, []);
  useEffect(() => {
    getProfile();
  }, []);
  useEffect(() => {
    cartSolution.subscribe((state: any) => {
      setDetailCart(state)
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);


  const handleClickOutside = (event) => {
    if (!event.target.closest('.container__cart') && !event.target.classList.contains('icon__cart') && detailCartRef.current && !detailCartRef.current.contains(event.target)) {
      setShowDetailCart(false);
    }
  };


  const extractLabels = (menuItems: { sectionLabel: any; items: any[] }[]) => {
    const labels: any[] = [];

    menuItems.forEach((menuItem: { sectionLabel: any; items: any[] }) => {
      labels.push(menuItem.sectionLabel);

      menuItem.items.forEach((item) => {
        labels.push({appName: item.appName, to: item.to, label: item.label});
      });
    });
    return labels;
  };

  const submitFeedbackButton = async () => {
    setThankYouState(true);
    await apiRequest({
      method: "post",
      url: apiRoutes.userFeedback,
      data: {
        comments: feedbackDescription,
        rating: feedbackRating,
        page_reference: feedbackNameLiteral.hasOwnProperty(pathname)
          ? feedbackNameLiteral[pathname]()
          : "Dashboard",
        pathname,
      },
    });
    setTimeout(() => {
      setThankYouState(false);
      setModalOpen(false);
      setFeedbackDescription("");
      setFeedbackRating(null);
    }, 4000);
  };

  const closeFeedbackButton = async () => {
    setModalOpen(false);
    setFeedbackDescription("");
    setFeedbackRating(null);
  };

  const feedbackNameLiteral = {
    "/": () => "Portal",
    "/home": () => "Portal",
    "/success-on-demand": () => "Success on Demand App",
    "/onboarding": () => "Onboarding App",
    "/users": () => "Administration App",
    "/health-check": () => "Health Check App",
    "/health-check-client": () => "Health Check App",
    "/health-check-data-viewer": () => "Health Check Data Viewer",
    "/migration-wizard": () => "Migration Visualization App",
    "/ps-status": () => "PS Status App",
    "/pd-university": () => "PDU Status App",
    "/deck-generator": () => "Deck Generator",
  };

  useEffect(() => {
    openMenuSubject.next(menuOpen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuOpen]);

  const onShowDetailCart = () => {
    setShowDetailCart(!showDetailCart)
  }

  const deleteCart = () => {
    setDetailCart([])
    localStorage.setItem("cart", JSON.stringify([]));
    cartSolution.next([]);
  }

  const deleteProduct  = (product) => {
    const filterProduct = detailCart?.filter((item) => item?.solution_id !== product)
    setDetailCart(filterProduct)
    cartSolution.next(filterProduct);
    localStorage.setItem("cart", JSON.stringify(filterProduct));
  }

  const solutionsCart = async () => {
    setLoadindRequestSent(true)
    const products = detailCart?.map((item:any) => {
      return {...item, quantity: 1 }})


    const [error, data] = userRole === "Free Trial Viewer" ? 
    await apiRequest({
      method: "post",
      url: apiRoutes.trialContact,
      data: {
        "request_details":products,
        section: "Solution Gallery"
      }
    }) : await apiRequest({
      method: "post",
      url: apiRoutes.solutionCart,
      data: {
        "solutions":products
      }
    });
  if (data?.status_code === 200) {
      userRole === "Free Trial Viewer" && setOpenFreeTrialModal(true);
      deleteCart()
      setRequestSentSuccess(true)
      setTimeout(() => {
        setRequestSentSuccess(false)
      }, 1000);
    }
    setShowDetailCart(false)
    setLoadindRequestSent(false);
  }


  const totalCost = detailCart.reduce((accumulator, currentValue) => {
    if(currentValue?.solution_classification_id === 1 ) {
      const hour = parseInt(currentValue?.solution_hour_cost)
      return accumulator + hour
    } else {
      return accumulator
    }
  }, 0)

  const sendAndOpenReconnect = () => {
    if(window.location.pathname !== '/') {
      navigate('')
      setTimeout(() => {
        reconnectModal.next(true);
      }, 1500);
    } else reconnectModal.next(true);
  }
  
  const StartTour = () => {
    const currentSteps = stepsState.getValue();
    const updatedSteps = { ...currentSteps };
  
    resetTourSteps.next(1);
  
    if (window.location.pathname !== '/') {
      updatedSteps.step1.isLoaded = true; // Actualiza el paso específico
      stepsState.next(updatedSteps);
      navigate('');
    } else {
      controlTour.next(true);
    }
  };

  return (
    <>
    <NavbarContainer>
        <h1>{getTitle(location.pathname)}</h1>
      {/* <LeftDiv>
        <MenuRegularIcon
          className={"center-corner"}
          onClick={() => setMenuOpen(!menuOpen)}
        >
          <FontAwesomeIcon icon={faBars} />
        </MenuRegularIcon>
      </LeftDiv> */}
      <DivFlex>
      {profile !== null && profile?.customer_type !== null ? (
      <button id="container__btnPremiumRequest" 
      className="btn btn-primary me-3 NewRequestButton" 
      onClick={() => userRole !== "Free Trial Viewer" ? handleChangePremiumRequest() : makeANewRequest()} 
      >
        New Request</button>
       ) : null}
      <SearchDivContainer>
        <SearchDiv>
          <SearchDivIcon>
            <FontAwesomeIcon icon={faSearch} />
          </SearchDivIcon>
          <SearchInput
            placeholder="Search by application"
            value={searchInput}
            onChange={(e: any) => setSearchInput(e.target.value)}
          />
        </SearchDiv>
        {searchInput?.length > 0 && (
          <RowSearchResults>
            <SearchTitleRow>Application Routes</SearchTitleRow>
            {extractLabels(menuItems)
              .filter((element) =>
                element?.appName?.toLowerCase().includes(searchInput.toLowerCase()) && userOptions.some((option) =>  option.appName === element.appName)
              )?.map((element) => (
                <OptionSearch
                  onClick={() => {
                    navigate(element.to);
                    setSearchInput("");
                  }}
                >
                  {element.label}
                </OptionSearch>
              ))}
          </RowSearchResults>
        )}
      </SearchDivContainer>
      {profile !== null && profile?.customer_type !== null ? (
      <ProfileSection>
        <div className="profileContainer">
          <img src={pageyUser}></img>
        </div>
        <div className="nameContainer">
          {userRole !== "Free Trial Viewer" ? tokenData.name : 'Guest'}
        </div>
      </ProfileSection>
      ) : null}
      {userRole === "Free Trial Viewer" && <div className="premiumInnovationDemoContainerTop">
        <div className="premiumInnovationDemoContainer">
        <div
              className="round__icon__modal"
              style={{ backgroundColor: "#005A24" }}
            >
              <img src={Start} alt="" role="display" />
            </div>
        <div className="premiumInnovationDemoText">Premium Customer Portal Experience</div>
        </div>
        <button className="heartbeatAnimation highlightButton" onClick={() => sendAndOpenReconnect()}>Explore Features <span><FontAwesomeIcon className='ms-2' icon={faPlus} /></span></button>
        <button className='highlightButton' onClick={() => StartTour()}>Start Tour </button>
        </div>}
      {pathname.includes('/solution-gallery') && 
        <div className="container__cart" onClick={() => onShowDetailCart()}>
          {detailCart.length > 0 && <div className="total__cart">{detailCart.length}</div>}
          <div className="icon__cart" style={userRole === "Free Trial Viewer" ? {marginRight: '24px'} : {}}><FontAwesomeIcon  icon={faCartShopping} /> </div>
        </div>}
        {(pathname.includes('/my-alerts-app')  || pathname === '/' || pathname === '/home' ) &&
          <div className="container__cart" onClick={() => showCartAlert.next(true)}>
            {cartAlert.length > 0 && <div className="total__cart">{cartAlert.length}</div>}
            <div className="icon__cart" style={userRole === "Free Trial Viewer" ? {marginRight: '24px'} : {}}><FontAwesomeIcon  icon={faCartShopping} /> </div>
          </div>}
      {showDetailCart && 
        <div className="detail__cart" ref={detailCartRef}>
          {loadindRequestSent && 
            <div className="spinner">
              <span></span>
              <span></span>
              <span></span>
            </div>}
            {!loadindRequestSent && 
              <div>
                <div className="clean__cart" onClick={() => deleteCart()}>
                  <span className="cleanCart__text">Clear Cart</span>
                  <FontAwesomeIcon icon={faTrash} />
                </div>
                <div className="container__cardCart">
                    {detailCart?.map(item => (
                        <div className="cardHorizontal__cart">
                          <div className="header__cardCart">
                            <img
                                className="img__cardCart"
                                src={healthCheck}
                              />
                          </div>
                          <div className="body__cardCart">
                            <div className="name__cardCart">
                              <div>{item?.solution_name}</div>
                            </div>
                            <div className="category__nameCar">
                              {item?.classification}
                            </div>
                            <div className="footer__hourCart">
                              {item?.solution_hour_cost ? (
                                <div className="hour__cost">
                                  {item?.solution_hour_cost}
                                </div>
                              ) : (
                                <div></div>
                              )}
                              <div
                                className="hour__cost"
                                onClick={() => deleteProduct(item.solution_id)}
                              >
                                <FontAwesomeIcon icon={faTrash} />{" "}
                              </div>
                            </div>
                          </div>
                        </div>
                    ))}
                </div>
                {detailCart?.length > 0 && <div className="footer__detailCart">
                  {detailCart?.length && totalCost > 0 && <div className="total__hours">Total Hours: {totalCost} (minimum)</div>}
                  {(detailCart?.length && totalCost > 0) || <div className="btn__placeholder"></div>}
                  {detailCart?.length && <button onClick={() =>solutionsCart()} className="btn__submitCart">Submit</button>}
                </div>}
                {!detailCart.length  && 
                  <div className="cart__isEmpty">
                    <div className="container__imgUserCart">
                      <img
                        className="img__pageyUserCart"
                        src={pageyUser}
                      />
                    </div>
                    <div className="not__cart">Your shopping cart is empty</div>
                  </div>}
              </div>}
        </div>}
        {requestSentSuccess &&
          <div className="detail__cartSucces" >
            <div className="cart__isEmptySend">
                <div className="container__imgUserCart">
                  <img
                    className="img__pageyUserCart"
                    src={pageyUser}
                  />
                </div>
                <div>Your request has been sent, we will contact you</div>
            </div>
          </div>}
      {/* <RightDiv>
        <FeedbackIcon
          onClick={() => setModalOpen(true)}
          className={"center-corner"}
        >
          <Like scale={0.025} />
        </FeedbackIcon>
        <FontAwesomeIcon style={{marginRight: '9px'}} icon={faMicrophone} />
      </RightDiv> */}
      </DivFlex>
    </NavbarContainer>
    <PremiumReqModalSection>
      {showRequestModal && <PremiumRequestModal showRequestModa={showRequestModal} subdomain={filterSubdomain.subdomain_name} email={email} setShowRequestModal={setShowRequestModal} />}
    </PremiumReqModalSection>
    <ModalHome
        show={openFreeTrialModal}
        handleClose={() =>
          setOpenFreeTrialModal(!openFreeTrialModal)
        }
        title={"New Request"}
        modalFitContent
      >
        <div>
          <div className="popupModalHomeSmall">
            <div
              className="round__icon__modal"
              style={{ backgroundColor: "#E2F0E7" }}
            >
              <img src={CloudCheckMark} alt="" role="display" />
            </div>
            <div className="popupModalHomeSmallText">
              <span className="popupModalHomeSecondSmallTextTitle">
              Thank you for your interest, your Customer Success Manager will follow up in the next{" "}
                <span className="popupModalHomeSecondBoldSmallTextTitle">
                72 business hours!
                </span>{" "}
              </span>
            </div>
          </div>
          <button
            onClick={() => {
              setOpenFreeTrialModal(false);
            }}
            className="popupModalHomeSmallButton"
          >
            Close
          </button>
        </div>
      </ModalHome>
    </>
  );
});

export default Navbar;
